import { round } from '@/helpers';
import EstimateField from './EstimateField';
import { getValueFromDto as getValue } from './_helper';

export interface EstimateItemDto {
    id?: number;
    parentItemId?: number | null;
    fieldId?: string | null;
    fieldOptionId?: string | null;
    value?: string | null;
    materialCost?: number;
    laborCost?: number;
    materialPrice?: number;
    laborPrice?: number;
    workflowEstimateId?: number;

    quantity?: number;
}

export default class EstimateItem {
    id: number;
    parentItemId: number | null;
    fieldId: string | null;
    fieldOptionId: string | null;
    value: string | null;
    materialCost: number;
    laborCost: number;
    materialPrice: number;
    laborPrice: number;
    quantity: number;
    workflowEstimateId: number;

    constructor(dto: EstimateItemDto) {
        this.id = getValue(dto, 'id', 'number', 0);
        this.parentItemId = getValue(dto, 'parentItemId', 'number', null);
        this.fieldId = getValue(dto, 'fieldId', 'string', null);
        this.fieldOptionId = getValue(dto, 'fieldOptionId', 'string', null);
        this.value = getValue(dto, 'value', 'string', null);
        this.materialCost = getValue(dto, 'materialCost', 'number', 0);
        this.laborCost = getValue(dto, 'laborCost', 'number', 0);
        this.materialPrice = getValue(dto, 'materialPrice', 'number', 0);
        this.laborPrice = getValue(dto, 'laborPrice', 'number', 0);
        this.quantity = getValue(dto, 'quantity', 'number', 0);
        this.workflowEstimateId = getValue(dto, 'workflowEstimateId', 'number', 1);
    }

    get workflowId() {
        return EstimateField.getWorkflowId(this.fieldId!);
    }

    incrementWorkflowEstimateId(): void {
        this.workflowEstimateId += 1;
    }

    get totalCost() {
        return this.materialCostTotal + this.laborCostTotal;
    }

    get materialCostTotal() {
        return round(this.materialCost * this.quantity, 2);
    }

    get laborCostTotal() {
        return round(this.laborCost * this.quantity, 2);
    }

    get total() {
        return this.materialPriceTotal + this.laborPriceTotal;
    }

    get materialPriceTotal() {
        return round(this.materialPrice * this.quantity, 2);
    }

    get laborPriceTotal() {
        return round(this.laborPrice * this.quantity, 2);
    }

    clone(): EstimateItem {
        return new EstimateItem(JSON.parse(JSON.stringify(this)));
    }

    equalTo(that: EstimateItem) {
        return (
            that instanceof EstimateItem &&
            this.fieldId === that.fieldId &&
            this.fieldOptionId === that.fieldOptionId &&
            this.value === that.value &&
            this.materialCost === that.materialCost &&
            this.laborCost === that.laborCost &&
            this.materialPrice === that.materialPrice &&
            this.laborPrice === that.laborPrice &&
            this.quantity === that.quantity
        );
    }
}
