/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import {
    getEstimateItemsFromValues,
    getFieldValuesFromEstimate,
    getSelectedOptionsForVariant,
    getValidFieldOptions,
    sortFieldIds,
} from '@/composables/estimate';
import { escapeRegex } from '@/helpers';
import Estimate from '@/models/Estimate';
import EstimateWorkflows from '@/models/EstimateWorkflows';

const packageFieldId = '2.88';
const laborFieldId = '2.104';
const colorFieldId = '2.89';
const sizeFieldId = '2.90';
const materialTypeFieldId = '2.187';

const otherFields = ['2.841', '2.845', '2.847', '2.43', '2.46'];

interface PackageOption {
    id: string;
    value: string;
    description: string;
    image?: { url: string };
}

interface ModelValues {
    [key: string]: {
        quantity: number;
        value: string;
    };
}

interface Field {
    property: string;
    workflowId: string;
    optionsByMaterialType: (materialType: string) => PackageOption[];
}

interface GetSidingPackagesParams {
    estimate: any; //no interface created
    fields: Record<string, any>;
    fieldOrder: string[];
    materialQty?: number;
    laborOptionId?: string;
    laborQty?: number;
    refreshSelectedPackage?: boolean;
    modelValues?: any;
}

interface Package {
    label?: string;
    id: string;
    name: string;
    description: string;
    imageUrl: string;
    price: number;
    selected: boolean;
    color: { id: string; name: string; imageUrl: string; selected: boolean } | null;
    colors: number;
    sizes?: { id: string; name: string; imageUrl: string; selected: boolean }[];
    estimate?: any;
    modelValues?: any;
}

export function getSidingPackagesQuickEstimate({
    estimate,
    fields,
    fieldOrder,
    materialQty = undefined,
    laborOptionId = undefined,
    laborQty = undefined,
    refreshSelectedPackage = false,
    modelValues,
}: GetSidingPackagesParams): Package[] {
    if (!estimate || !fields) {
        return [];
    }

    let fieldIds = [packageFieldId, laborFieldId, colorFieldId, sizeFieldId, materialTypeFieldId, ...otherFields];
    fieldIds = sortFieldIds(fieldIds, fieldOrder);

    const packageField = fields[packageFieldId];
    const colorField = fields[colorFieldId];
    const sizeField = fields[sizeFieldId];
    const laborField = fields[laborFieldId];

    const workflowId = packageField.workflowId;
    estimate = (() => {
        const e = new Estimate({});
        e.items = estimate.items.filter((x: { workflowId: string }) => x.workflowId === workflowId);
        const workflowKey = EstimateWorkflows.map[workflowId].key;
        e.workflowSummaries[workflowKey] = estimate.workflowSummaries[workflowKey];
        return e.clone();
    })();

    const defaultModelValues = modelValues ?? getFieldValuesFromEstimate(estimate.itemTreeByField, fields, fieldIds);
    if (!(defaultModelValues[packageField.property].quantity! > 0) && !(!isNaN(materialQty!) && materialQty! > 0)) {
        return [];
    }

    if (!isNaN(materialQty!) && materialQty! > 0) {
        defaultModelValues[packageField.property].quantity = materialQty!;
        defaultModelValues[colorField.property].quantity = materialQty!;
        if (sizeField) {
            defaultModelValues[sizeField.property].quantity = materialQty!;
        }
    }

    if (laborOptionId !== undefined && laborField) {
        defaultModelValues[laborField.property].value = laborOptionId;
    }

    if (!isNaN(laborQty!) && laborQty! > 0 && laborField) {
        defaultModelValues[laborField.property].quantity = laborQty!;
    }

    // calculate estimate workflow total for each comparison option
    const undecidedPattern = /undecided/i;
    return packageField
        .optionsByMaterialType(defaultModelValues.materialType.value)
        .map((packageOption: { id: string; value: string; description: string; image: { url: string } }) => {
            const e = estimate.clone();
            const modelValues = JSON.parse(JSON.stringify(defaultModelValues));
            const po: Package = {
                id: packageOption.id,
                name: packageOption.value,
                description: packageOption.description,
                imageUrl: packageOption.image ? packageOption.image.url : '',
                price: 0,
                selected: packageOption.id === modelValues[packageField.property].value,
                color: null,
                colors: 0,
                estimate: null,
                modelValues: null,
            };
            const allValidOptions: { [key: string]: any[] } = {};
            if (!po.selected || refreshSelectedPackage) {
                // set package
                modelValues[packageField.property].value = packageOption.id;
                // check other fields, setting them to the first valid option if needed
                for (const fieldId of fieldIds) {
                    if (fieldId === packageFieldId) continue;
                    const field = fields[fieldId];
                    const selectedOptions = getSelectedOptionsForVariant(
                        fields,
                        e.itemTreeByField,
                        modelValues,
                        field.property
                    );
                    const validOptions = getValidFieldOptions(selectedOptions, field);
                    const selectedValue = modelValues[field.property].value;
                    const newValue =
                        validOptions.length > 0
                            ? (validOptions.find((x) => x.id === selectedValue) ?? validOptions[0]).id
                            : null;
                    modelValues[field.property].value = newValue;
                    allValidOptions[fieldId] = validOptions;
                }

                const newItems = getEstimateItemsFromValues(modelValues, fieldIds, e.itemTreeByField, fields);
                e.updateItems(newItems, fieldIds, fields, true);
            } else {
                for (const fieldId of [colorFieldId, sizeFieldId]) {
                    const field = fields[fieldId];
                    const selectedOptions = getSelectedOptionsForVariant(
                        fields,
                        e.itemTreeByField,
                        modelValues,
                        field.property
                    );
                    const validOptions = getValidFieldOptions(selectedOptions, field);
                    allValidOptions[fieldId] = validOptions;
                }
            }
            const packageOptionRegex = new RegExp(escapeRegex(packageOption.value), 'i');
            const colors = allValidOptions[colorFieldId].map((x) => ({
                id: x.id,
                name: x.value.replace(packageOptionRegex, '').trim(),
                imageUrl: x.image ? x.image.url : '',
                selected: false,
            }));
            po.colors = colors.filter((x) => !undecidedPattern.test(x.name)).length;
            if (colors.length > 0) {
                po.color = colors.find((x) => x.id === modelValues[colorField.property].value) || null;
                if (po.color) {
                    po.color.selected = po.selected && !undecidedPattern.test(po.color.name);
                }
            }
            if (sizeField) {
                po.sizes = allValidOptions[sizeFieldId].map((x) => ({
                    id: x.id,
                    name: x.value.replace(packageOptionRegex, '').trim(),
                    imageUrl: x.image ? x.image.url : '',
                    selected: po.selected && x.id === modelValues[sizeField.property].value,
                }));
            }

            po.price = e.getWorkflowSummary(workflowId).totalWithMargin;
            po.estimate = e;
            return po;
        })
        .sort((a: { price: number }, b: { price: number }) => a.price - b.price);
}

export function getSidingPackages({
    estimate,
    fields,
    fieldOrder,
    materialQty = undefined,
    laborOptionId = undefined,
    laborQty = undefined,
    refreshSelectedPackage = false,
}: GetSidingPackagesParams): Package[] {
    if (!estimate || !fields) {
        return [];
    }

    let fieldIds = [packageFieldId, laborFieldId, colorFieldId, sizeFieldId, materialTypeFieldId, ...otherFields];
    fieldIds = sortFieldIds(fieldIds, fieldOrder);

    const packageField = fields[packageFieldId];
    const colorField = fields[colorFieldId];
    const sizeField = fields[sizeFieldId];
    const laborField = fields[laborFieldId];
    const materialTypeField = fields[materialTypeFieldId];

    const workflowId = packageField.workflowId;
    estimate = (() => {
        const e = new Estimate({}); //no interface created
        e.items = estimate.items.filter((x: { workflowId: string }) => x.workflowId === workflowId);
        const workflowKey = EstimateWorkflows.map[workflowId].key;
        e.workflowSummaries[workflowKey] = estimate.workflowSummaries[workflowKey];
        return e.clone();
    })();

    const defaultModelValues: ModelValues = getFieldValuesFromEstimate(
        estimate.itemTreeByField,
        fields,
        fieldIds
    ) as ModelValues;

    // Ensure materialQty is a number and defined
    if (!(defaultModelValues[packageField.property].quantity! > 0) && !(!isNaN(materialQty!) && materialQty! > 0)) {
        return [];
    }

    if (!isNaN(materialQty!) && materialQty! > 0) {
        defaultModelValues[packageField.property].quantity = materialQty!;
        defaultModelValues[colorField.property].quantity = materialQty!;
        if (sizeField) {
            defaultModelValues[sizeField.property].quantity = materialQty!;
        }
    }

    if (laborOptionId !== undefined && laborField) {
        defaultModelValues[laborField.property].value = laborOptionId;
    }

    if (!isNaN(laborQty!) && laborQty! > 0 && laborField) {
        defaultModelValues[laborField.property].quantity = laborQty!;
    }

    // calculate estimate workflow total for each comparison option
    const undecidedPattern = /undecided/i;
    return packageField
        .optionsByMaterialType(defaultModelValues.materialType.value)
        .map((packageOption: { id: string; value: string; description: string; image: { url: string } }) => {
            const e: any = estimate.clone();
            const modelValues = JSON.parse(JSON.stringify(defaultModelValues));
            const po: Package = {
                id: packageOption.id,
                name: packageOption.value,
                description: packageOption.description,
                imageUrl: packageOption.image ? packageOption.image.url : '',
                price: 0,
                selected: packageOption.id === modelValues[packageField.property].value,
                color: null,
                colors: 0,
                label: '',
            };
            const allValidOptions: { [key: string]: any[] } = {};
            if (!po.selected || refreshSelectedPackage) {
                // set package
                modelValues[packageField.property].value = packageOption.id;
                // check other fields, setting them to the first valid option if needed
                for (const fieldId of fieldIds) {
                    if (fieldId === packageFieldId) continue;
                    const field = fields[fieldId];

                    if (Array.isArray(modelValues[field.property])) {
                        for (let i = 0; i < modelValues[field.property].length; i++) {
                            for (let j = 0; j < field.childIds.length; j++) {
                                const childField = fields[field.childIds[j]];
                                if (modelValues[field.property][i].children == undefined) continue;
                                const validOptions = getValidFieldOptions(
                                    {},
                                    childField,
                                    modelValues[materialTypeField.property].value
                                );
                                console.log('validOptions', validOptions);
                                const selectedValue =
                                    modelValues[field.property][i].children[childField.property].value;
                                console.log('selectedValue', selectedValue);
                                const newValue = validOptions.length > 0 ? validOptions[0].id : null;
                                console.log('newValue', newValue);
                                modelValues[field.property][i].children[childField.property].value = newValue;
                                allValidOptions[field.childIds[j]] = validOptions;
                            }
                        }
                    } else {
                        const selectedOptions = getSelectedOptionsForVariant(
                            fields,
                            e.itemTreeByField,
                            modelValues,
                            field.property
                        );
                        const validOptions = getValidFieldOptions(selectedOptions, field);
                        const selectedValue = modelValues[field.property].value;
                        const newValue =
                            validOptions.length > 0
                                ? (validOptions.find((x) => x.id === selectedValue) ?? validOptions[0]).id
                                : null;
                        modelValues[field.property].value = newValue;
                        allValidOptions[fieldId] = validOptions;
                    }
                }

                const newItems = getEstimateItemsFromValues(modelValues, fieldIds, e.itemTreeByField, fields);
                e.updateItems(newItems, fieldIds, fields, true);
            } else {
                for (const fieldId of [colorFieldId, sizeFieldId]) {
                    const field = fields[fieldId];
                    const selectedOptions = getSelectedOptionsForVariant(
                        fields,
                        e.itemTreeByField,
                        modelValues,
                        field.property
                    );
                    const validOptions = getValidFieldOptions(selectedOptions, field);
                    allValidOptions[fieldId] = validOptions;
                }
            }

            const packageOptionRegex = new RegExp(escapeRegex(packageOption.value), 'i');
            const colors = allValidOptions[colorFieldId].map((x) => ({
                id: x.id,
                name: x.value.replace(packageOptionRegex, '').trim(),
                imageUrl: x.image ? x.image.url : '',
                selected: false,
            }));
            po.colors = colors.filter((x) => !undecidedPattern.test(x.name)).length;

            if (colors.length > 0) {
                po.color = colors.find((x) => x.id === modelValues[colorField.property].value) || null;
                if (po.color) {
                    po.color.selected = po.selected && !undecidedPattern.test(po.color.name);
                }
            }
            if (sizeField) {
                po.sizes = allValidOptions[sizeFieldId].map((x) => ({
                    id: x.id,
                    name: x.value.replace(packageOptionRegex, '').trim(),
                    imageUrl: x.image ? x.image.url : '',
                    selected: po.selected && x.id === modelValues[sizeField.property].value,
                }));
            }

            po.price = e.getWorkflowSummary(workflowId).totalWithMargin;
            return po;
        })
        .sort((a: { price: number }, b: { price: number }) => a.price - b.price);
}
